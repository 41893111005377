import { Stack, Switch } from "@mui/material";
import { useState } from "react";
import { Goal } from "../../types/types";
import { GoalGraphPerDay } from "./GoalGraphPerDay";
import { GoalGraphPerGoal } from "./GoalGraphPerGoal";

export const GoalGraphSelection = ({
  goals,
  date,
}: {
  goals: Goal[];
  date: Date;
}) => {
  const [progressPerDate, setProgressPerDate] = useState(true);

  return (
    <Stack>
      <Stack direction={"row"} alignItems="center">
        Progress by Date
        <Switch
          value={progressPerDate}
          onChange={() => setProgressPerDate((s) => !s)}
        ></Switch>
        Progress by Goal
      </Stack>

      <br />

      {progressPerDate ? (
        <GoalGraphPerDay goals={goals} date={date}></GoalGraphPerDay>
      ) : (
        <GoalGraphPerGoal goals={goals} date={date}></GoalGraphPerGoal>
      )}
    </Stack>
  );
};
