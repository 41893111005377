import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Stack, IconButton, Box, CircularProgress, Switch } from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { Goal } from "../../types/types";
import { months, daysInMonth, dayAbbreviations } from "../utils/utils";
import { AddGoal } from "./AddGoal";
import { GoalGraphSelection } from "./GoalGraphSelection";
import { GoalRow } from "./GoalRow";
import { ReorderableGoals } from "./ReorderableGoals";
import Cookies from 'js-cookie';

export const GoalsDisplayHolder = ({ goals }: { goals: Goal[] }) => {
  const [date, setDate] = useState(new Date());

  var mm = date.getMonth(); // getMonth() is zero-based

  var yy = date.getFullYear();

  const display = `${months[mm]}, ${yy}`;

  const changeMonth = (delta: number) => {
    setDate((d) => new Date(d.setMonth(d.getMonth() + delta)));
  };

  const total_days = daysInMonth(date);
  const todayDate = useMemo(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [seePast, setSeePast] = useState(Cookies.get('seePast') === undefined ? true :  Cookies.get('seePast') === 'true');

  useEffect(() => {
    if (Cookies.get('seePast') == undefined) Cookies.set('seePast', "false", { expires: 365 });
  }, []);
  

  // Update the cookie when the state changes
  useEffect(() => {
    Cookies.set('seePast', seePast.toString(), { expires: 365 }); // Store the state for 1 year
  }, [seePast]);

  // Toggle the state
  const toggleSeePast = () => {
    setSeePast((prevSeePast) => !prevSeePast);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 10000);
  
    return () => clearTimeout(timer); // This will clear the timeout if the component unmounts before 10 seconds
  }, []);

  return (
    <>
      <Box>
        <Stack direction="row" alignItems={"center"}>
          <IconButton onClick={() => changeMonth(-1)}>
            <ChevronLeft />
          </IconButton>

          <p>{display}</p>

          <IconButton onClick={() => changeMonth(1)}>
            <ChevronRight />
          </IconButton>
        </Stack>
      </Box>

      <Box margin={"32px"}>
      {isLoading && goals.length === 0 ? (
  <CircularProgress />
) : !isLoading && goals.length === 0 ? (
  <Box>
    <p>No goals to show!</p>
  </Box>
) : (
  <Box>
    <Box>
      See past
      <Switch checked={seePast} onClick={toggleSeePast}></Switch>
    </Box>
          <Stack direction={"row"} alignItems="center">
            <Stack
              sx={{
                width: { xs: seePast ? "50%": "80%", md:  "30%" },
                overflow: { xs: "scroll", md: "auto" },
                paddingTop: "38px",
                borderRight: "1px #999 solid",
              }}
            >
              <ReorderableGoals _goals={goals} date={date} />
            </Stack>

            <Stack
              sx={{ overflowX: "scroll", width: { xs: seePast ? "50%": "20%", md: "70%" } }}
            >
              <Stack direction="row" alignItems={"center"}>
                {" "}
                {[...Array(total_days)].slice(seePast ? 0: todayDate.getDate(), seePast ? total_days-1: todayDate.getDate()+1).map((_, i) => {
                  const eachDate = new Date(
                    date.getFullYear(),
                    date.getMonth(),
                    (seePast ? 0: todayDate.getDate() - 1 ) + i + 1
                  );

                  return (
                    <Box
                      sx={{
                        minWidth: "26px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        whiteSpace: "nowrap",
                        borderRight: "1px #999 solid",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        background:
                          todayDate.getTime() === eachDate.getTime()
                            ? "#ccc"
                            : eachDate.getDay() === 0
                            ? "#eee"
                            : undefined,
                      }}
                      key={`GoalDay.${i}`}
                    >
                      <Box>{dayAbbreviations[eachDate.getDay()]}</Box>

                      <Box>{ eachDate.getDate()}</Box>
                    </Box>
                  );
                })}
              </Stack>
              {goals?.map((goal, i) => (
                <GoalRow
                  goal={goal}
                  key={`GoalRow.${goal?.goal}.${date.toString()}`}
                  index={i}
                  date={date}
                  seePast={seePast}
                />
              ))}
            </Stack>
          </Stack>
          </Box>
        )}

        <p>
          Your ideal day takes: {goals.reduce((a, c) => a + c.duration, 0)} min
          a day
        </p>

        <AddGoal goals={goals} />

        <Box sx={{ marginTop: "64px" }}>
          <GoalGraphSelection goals={goals} date={date} />
        </Box>
      </Box>
    </>
  );
};
