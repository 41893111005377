import { DragHandle, Edit } from "@mui/icons-material";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
import { useState } from "react";
import { Goal, goalTypes } from "../../types/types";
import PieChart from "../utils/PieChart";
import { daysInMonth, getStreakOfGoal, goalsLastNDays, goalsThisMonth } from "../utils/utils";
import { GoalRowHeaderEdit } from "./GoalRowHeaderEdit";

export const GoalRowHeader = ({
  goal,
  index,
  date,
}: {
  goal: Goal;
  index: number;
  date: Date;
}) => {
  const [edit, setEdit] = useState(false);
  const streak = getStreakOfGoal(goal, date);

  // Calculate completion percentage for the last 5 days
  const last5Days = 5;
  const goalsLast5Days = goalsLastNDays(goal, last5Days)
  const completionPercentage = (goalsLast5Days / last5Days) * 100;


function perc2color(perc: number) {
    var r, g, b = 0;
    if(perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
    }
    else {
      g = 255;
      r = Math.round(510 - 5.10 * perc);
    }
    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return '#' + ('000000' + h.toString(16)).slice(-6);
  }



  return edit ? (
    <>
      <GoalRowHeaderEdit goal={goal} callback={() => setEdit(false)} />
    </>
  ) : (
    <Stack
      direction="row"
      alignItems={"center"}
      sx={{
        paddingRight: "16px",
        background: index % 2 === 0 ? "#eee" : "auto",
        width: "200%",
      }}
      borderBottom={"#eee 1px solid"}
      maxHeight={"48px"}
      minHeight={"48px"}
    >
      {/* <DragHandle sx={{ marginLeft: "16px", marginRight: "8px" }} /> */}

      <Tooltip
          title={`${goalsLast5Days} / ${last5Days} Days completed`}
        >
      <Box
        sx={{
          minWidth: "12px",
          width: "12px",
          height: "12px",
          background: perc2color(completionPercentage),
          borderRadius: "12px",
          marginRight: "8px",
          marginLeft: "8px",
          border: "1px solid black"
        }}
      ></Box>
      </Tooltip>

      <Box sx={{ minWidth: "200px", maxWidth: "200px", whiteSpace: "revert" }}>
        {goal.goal} {streak ? " (" + streak + " 🔥)" : null}
      </Box>

      <Box sx={{ minWidth: "75px", maxWidth: "75px", whiteSpace: "nowrap" }}>
        {goal.duration} min
      </Box>

      <Box sx={{ marginRight: "16px" }}>
        <Tooltip
          title={`${goalsThisMonth(goal, date)} / ${daysInMonth(
            date
          )} Days completed`}
        >
          <Box>
            <PieChart
              percentage={
                (goalsThisMonth(goal, date) / daysInMonth(date)) * 100
              }
              size={32}
              fillColor={ goal.type ? goalTypes[goal.type as keyof typeof goalTypes].color : "grey"}
              strokeColor={ goal.type ? goalTypes[goal.type as keyof typeof goalTypes].color : "grey"}
              strokeWidth={16}
            />  
          </Box>
        </Tooltip>  
      </Box>

      

      <Tooltip title={`Edit Goal`}>
        <IconButton
          onClick={() => {
            setEdit(true);
          }}
        >
          <Edit />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
