import { Box } from "@material-ui/core";
import { Goal } from "../../types/types";
import CanvasJSReact from "../../canvasjs.react";
import { months } from "../utils/utils";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const GoalGraphPerDay = ({
  goals,
  date,
}: {
  goals: Goal[];
  date: Date;
}) => {
  const monthFirst = new Date(date.getFullYear(), date.getMonth(), 1);


  const dataset: any = [];

  let index = 0;
  for (
    let i = monthFirst.getTime();
    i <= date.getTime();
    i += 24 * 60 * 60 * 1000
  ) {
    let total = 0;
   
    for (let j = 0; j < goals.length; j++) {
      const dateStrings = goals[j]?.days?.map(each => new Date(each).toDateString())
      if (dateStrings.includes(new Date(i).toDateString())) {
        total += 1;
      }
    }
    dataset.push({ x: index + 1, y: total });
    index += 1;
  }

  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"
    axisY: {
      title: "Days of Progress",
    },
    axisX: {
      title: `${months[date.getMonth()]}, ${date.getFullYear()}`,
      suffix: "th",
      interval: 2,
    },
    data: [
      {
        type: "line",
        toolTipContent: `Day ({x}), Progress ({y})`,
        dataPoints: dataset,
      },
    ],
  };

  if (goals.length === 0) return <></>;

  return (
    <Box>
      <CanvasJSChart options={options} />
    </Box>
  );
};
