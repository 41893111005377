import { Goal } from "../../types/types";

export const getStreakOfGoal = (goal: Goal, date: Date) => {
  const monthFirst = new Date(date.getFullYear(), date.getMonth(), 1);
  const dayDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  let curr = dayDate.getTime();
  const oneDay = 24 * 60 * 60 * 1000;

  let streak = 0;
  while (curr > monthFirst.getTime()) {
    if (goal.days.includes(curr)) {
      streak += 1;
      curr -= oneDay;
    } else if (curr === dayDate.getTime()) {
      curr -= oneDay;
    } else {
      break;
    }
  }

  return streak;
};

export const goalsThisMonth = (goal: Goal, date: Date) => {
  const monthFirst = new Date(date.getFullYear(), date.getMonth(), 1);

  const monthLast = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return goal?.days?.filter(
    (day) => day >= monthFirst.getTime() && day <= monthLast.getTime()
  ).length;
};

export const goalsLastNDays = (goal: Goal, n: number) => {
  const nDaysAgo = new Date();
  nDaysAgo.setDate(nDaysAgo.getDate() - n);

  const lastNDaysGoals = goal?.days?.filter((timestamp) => {
    const dayDate = new Date(timestamp);
    return dayDate >= nDaysAgo;
  });

  return lastNDaysGoals?.length || 0;
};


export const daysInMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const dayAbbreviations = ["S", "M", "T", "W", "Th", "F", "S"];
