export interface Goal {
  id: string;
  goal: string;
  days: number[];
  duration: number;
  uid: string;
  index: number;
  type: string;
}


export const goalTypes = {
  morning: {
    color: 'orange',
    label: 'Morning',
  },
  fitness: {
    color: 'green',
    label: 'Fitness',
  },
  creative: {
    color: 'cyan',
    label: 'Creative/Art',
  },
  learning: {
    color: 'pink',
    label: 'Learning',
  },
  evening: {
    color: 'purple',
    label: 'Evening',
  },
  weekly: {
    color: 'blue',
    label: 'Weekly',
  },
  other: {
    color: 'grey',
    label: 'Other',
  }
};
