import { Stack, TextField, Button, Select, MenuItem, ListItemIcon, SvgIcon  } from "@mui/material";
import { useState } from "react";
import Goals from "../../models/Goals";
import { Goal, goalTypes } from "../../types/types";




export const AddGoal = ({ goals }: { goals: Goal[] }) => {
  const [goalName, setGoalName] = useState("");
  const [goalDuration, setGoalDuration] = useState(0);
  const [goalType, setGoalType] = useState("weekly");

  return (
    <Stack direction="row" alignItems="center" sx={{ marginTop: "32px" }} spacing={1}>
      <TextField
        value={goalName}
        onChange={(e) => setGoalName(e.target.value)}
        label="Goal Name"
      />
      <TextField
        value={goalDuration}
        onChange={(e) => setGoalDuration(parseInt(e.target.value))}
        type="number"
        label="Goal Duration (min)"
      />
      <Select
        value={goalType}
        onChange={(e) => setGoalType(e.target.value)}
        label="Goal Type"
      >
        {Object.entries(goalTypes).map(([value, { color, label }]) => (
          <MenuItem value={value} key={value}>
            <ListItemIcon>
              <SvgIcon>
                <circle cx="12" cy="12" r="8" fill={color} />
              </SvgIcon>
            </ListItemIcon>
            {label}
          </MenuItem>
        ))}
      </Select>
      <Button
        onClick={() => {
          Goals.add(goalName, goalDuration, goals.length, goalType);
          setGoalName("");
          setGoalDuration(0);
          setGoalType(goalType); // Set the default kind to daily after adding a goal
        }}
        variant="contained"
      >
        Add Goal
      </Button>
    </Stack>
  );
};
