import { query, where, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { auth } from "../../config/firebase";
import { Goal, goalTypes } from "../../types/types";
import Goals from "../../models/Goals";
import { GoalsDisplayHolder } from "./GoalsDisplayHolder";

export const GoalsDisplay = () => {
  const [goals, setGoals] = useState<Goal[]>([]);
  useEffect(() => {
    if (!auth.currentUser || !auth.currentUser?.uid) return;
    const q = query(
      Goals.goalsCollection,
      where("uid", "==", auth.currentUser?.uid)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newGoals = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      newGoals.sort((a, b) => (a.index < b.index ? -1 : 1));

      newGoals.sort((a, b) => {
        const goalTypesArray = Object.keys(goalTypes);
        const aIndex = goalTypesArray.indexOf(a.type);
        const bIndex = goalTypesArray.indexOf(b.type);
      
        if (aIndex === -1) return 1; // a has no goal type, so it goes last
        if (bIndex === -1) return -1; // b has no goal type, so it goes last
      
        return aIndex - bIndex; // sort by goal type index
      });

      setGoals(newGoals);
    });
    return () => unsubscribe();
  }, [setGoals]);

  return <GoalsDisplayHolder goals={goals} />;
};
