import { Stack, Checkbox, Box } from "@mui/material";
import { useState } from "react";
import Goals from "../../models/Goals";
import { Goal } from "../../types/types";
import { daysInMonth } from "../utils/utils";

export const GoalRow = ({
  goal,
  index,
  date,
  seePast
}: {
  goal: Goal;
  index: number;
  date: Date;
  seePast: boolean;
}) => {
  const [active, setActive] = useState(false);

  const[_goal, setGoal] = useState(goal)

  const now = new Date();
  const todayDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());


  const [checkedDays, setCheckedDays] = useState(new Set(_goal?.days));


  return (
    <Stack
      direction="row"
      alignItems={"center"}
      borderBottom={"#eee 1px solid"}
      maxHeight={"48px"}
      minHeight={"48px"}
      sx={{
        background: active ? "#ccc" : index % 2 === 0 ? "#eee" : "auto",
        width: "fit-content",
      }}
    >
      {[...Array(daysInMonth(date))].slice(seePast ? 0: now.getDate()-1, now.getDate()).map((_, i) => {
        const dayDate = new Date(date.getFullYear(), date.getMonth(), (seePast ? 0: todayDate.getDate() - 1 ) + i + 1);
        const dayDateMillis = dayDate.getTime();

        return (
          <Box
            key={`GoalCheckbox.${_goal?.goal}.${dayDateMillis}`}
            sx={{
              borderRight: "1px #999 solid",
              background:
                todayDate.getTime() === dayDate.getTime() ? "#ccc" : undefined,
            }}
          >
            <Checkbox
              checked={checkedDays.has(dayDateMillis)}
              onMouseMoveCapture={() => {
                setActive(true);
              }}
              onMouseLeave={() => {
                setActive(false);
              }}
              onClick={() => {
                const newCheckedDays = new Set(checkedDays);
                if (newCheckedDays.has(dayDateMillis)) {
                  newCheckedDays.delete(dayDateMillis);
                } else {
                  newCheckedDays.add(dayDateMillis);
                }
                setCheckedDays(newCheckedDays);
                Goals.update(_goal, { days: Array.from(newCheckedDays) });
              }}
            />
          </Box>
        );
      })}
    </Stack>
  );
};
