import React, { FC } from "react";

interface Props {
  percentage: number;
  size: number;
  strokeWidth: number;
  strokeColor: string;
  fillColor: string;
}

const PieChart: FC<Props> = ({
  percentage,
  size,
  strokeWidth,
  strokeColor,
  fillColor,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={{ transform: "rotate(-90deg)", border: "1px solid black", borderRadius: "50%" }} // Add 1px black border
    >
      <g>
      <circle
  cx={size / 2}
  cy={size / 2}
  r={radius}
  fill="none"
  strokeWidth={strokeWidth}
  stroke={fillColor}

/>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          stroke={"black"}
          style={{
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: offset,
            transition: "stroke-dashoffset 0.5s ease 0s",
          }}
        />

      </g>
    </svg>
  );
};

export default PieChart;
