import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { Goal } from "../types/types";
import { auth } from "../config/firebase";

export default class Goals {
  static db_name = "goals";
  static goalsCollection = collection(
    db,
    this.db_name
  ) as CollectionReference<Goal>;

  static async add(goal: string, duration: number, index: number, goalType: string) {
    try {
      await addDoc(collection(db, this.db_name), {
        goal,
        duration,
        days: [],
        index,
        uid: auth.currentUser?.uid,
        type: goalType
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  static async delete(id: string) {
    await deleteDoc(doc(db, this.db_name, id));
  }

  static async update(goal: Goal, updated: any) {
    const ref = doc(db, this.db_name, goal.id!);
    setDoc(ref, { ...goal, ...updated });
  }
}
