import { Box } from "@mui/material";
import { GoalsDisplay } from "../components/goals/GoalsDisplay";
import ResponsiveAppBar from "../components/NavBar";

const Home = () => {
  return (
    <div>
      <ResponsiveAppBar></ResponsiveAppBar>
      <Box sx={{ marginTop: "32px" }}>
        <GoalsDisplay />
      </Box>
    </div>
  );
};

export default Home;
