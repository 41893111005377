import { debounce } from "lodash";
import React, { useState, useEffect } from "react";
import Goals from "../../models/Goals";
import { Goal } from "../../types/types";
import { GoalRowHeader } from "./GoalRowHeader";
import { ReactSortable } from "react-sortablejs";

interface GoalModified extends Goal {
  hiddenId?: string;
}
export const ReorderableGoals = ({
  _goals,
  date,
}: {
  _goals: Goal[];
  date: Date;
}) => {
  const [goals, setGoals] = useState<GoalModified[]>(
    _goals.map((g, i) => ({
      ...g,
      id: g.index.toString(),
      hiddenId: g.id,
      name: g.goal,
    }))
  );

  const debouncedUpdate = React.useRef(
    debounce(async (_updatedGoals: GoalModified[], originalGoals: Goal[]) => {
      const updatedGoals: Goal[] = _updatedGoals.map((g, i) => ({
        ...g,
        id: g.hiddenId!,
        index: i,
      }));

      const updateThese: Goal[] = [];

      updatedGoals.forEach((g, i) => {
        if (originalGoals[i].index === i && originalGoals[i].id === g.id)
          return;

        updateThese.push(g);
      });

      updateThese.forEach(async (g) => {
        await Goals.update(modifiedToGoal(g), { index: g.index });
      });
    }, 5 * 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      debouncedUpdate.cancel();
    };
  }, [debouncedUpdate]);

  useEffect(() => {
    setGoals(
      _goals.map((g, i) => ({ ...g, id: g.index.toString(), hiddenId: g.id }))
    );
  }, [_goals]);

  const handleChange = (reorderedItems: GoalModified[]) => {
    setGoals(reorderedItems);
    debouncedUpdate(reorderedItems as unknown as GoalModified[], _goals);
  };

  const modifiedToGoal = (modified: GoalModified): Goal => {
    const clone: Goal = (({ hiddenId, ...o }) => o)(modified);
    clone.id = modified.hiddenId!;
    return clone;
  };

  return (
    // <ReactSortable list={goals} setList={handleChange} >
    <>
      {goals.map((goal, i) => (
        <GoalRowHeader
          goal={modifiedToGoal(goal as unknown as GoalModified)}
          key={`GoalRowHeader.${goal.goal}.${goal.index}`}
          index={i}
          date={date}
        />
      ))}
      </>
    // </ReactSortable>
  );
};
