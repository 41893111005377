import { Grid, FormControlLabel, Checkbox, Box } from "@mui/material";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";
import CanvasJSReact from "../../canvasjs.react";
import { Goal } from "../../types/types";
import { goalsThisMonth, months } from "../utils/utils";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export const GoalGraphPerGoal = ({
  goals: _goals,
  date,
}: {
  goals: Goal[];
  date: Date;
}) => {
  const monthFirst = new Date(date.getFullYear(), date.getMonth(), 1);

  const monthLast = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const goals = useMemo(() => [..._goals], [_goals]);

  goals.sort((a, b) =>
    goalsThisMonth(a, date) > goalsThisMonth(b, date) ? -1 : 1
  );

  const [selectedGoalIndexes, setSelectedGoalIndices] = useState<Set<number>>(
    new Set([])
  );

  const selectAll = useCallback(
    () => setSelectedGoalIndices(new Set(goals.map((_: any, i: number) => i))),
    [setSelectedGoalIndices, goals]
  );

  const ref = useRef<Goal[]>();

  useEffect(() => {
    if (selectedGoalIndexes.size === 0 && goals !== ref.current) {
      selectAll();
    }

    ref.current = goals;
  }, [goals, selectAll, selectedGoalIndexes.size]);

  const data: any = [];
  selectedGoalIndexes.forEach((goalIndex) => {
    const goalData = [];
    const goal = goals[goalIndex];
    if (goal === undefined) return;

    for (let i = 0; i < date.getDate(); i++) {
      const progressDate = new Date(date.getFullYear(), date.getMonth(), i + 1);
      const progressSoFar = goal?.days
        ?.filter(
          (day) => day >= monthFirst.getTime() && day <= monthLast.getTime()
        )
        .reduce((a: any, c: any) => {
          if (c <= progressDate.getTime()) return a + 1;
          return a;
        }, 0);

      const dataPoint = { x: i + 1, y: progressSoFar };

      goalData.push(dataPoint);
    }

    data.push({
      type: "line",
      toolTipContent: `${goal?.goal}: Day ({x}), Total ({y})`,
      dataPoints: goalData,
    });
  });

  const options = {
    animationEnabled: true,
    exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"
    axisY: {
      title: "Days of Progress",
    },
    axisX: {
      title: `${months[date.getMonth()]}, ${date.getFullYear()}`,
      suffix: "th",
      interval: 2,
    },
    data,
  };

  const checkedAll = goals?.every((_: any, i: number) =>
    selectedGoalIndexes.has(i)
  );

  if (goals.length === 0) return <></>;

  return (
    <Box>
      {selectedGoalIndexes.size === 0 && <p>No goals selected!</p>}

      <CanvasJSChart options={options} />
      <Grid container marginTop={"32px"}>
        <Grid xs={6} md={4} item>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedAll}
                onClick={() => {
                  if (checkedAll) {
                    setSelectedGoalIndices(new Set());
                  } else {
                    selectAll();
                  }
                }}
              />
            }
            label={"Select All"}
          />
        </Grid>
        {goals.map((goal: Goal, i: number) => (
          <Grid item key={`GoalGraphCheckbox.${goal?.goal}.${i}`} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedGoalIndexes.has(i)}
                  onClick={() => {
                    if (selectedGoalIndexes.has(i)) {
                      setSelectedGoalIndices((s) => {
                        const c = new Set(s);
                        c.delete(i);
                        return c;
                      });
                    } else {
                      setSelectedGoalIndices((s) => {
                        const c = new Set(s);
                        c.add(i);
                        return c;
                      });
                    }
                  }}
                />
              }
              label={`(${goalsThisMonth(goal, date)}) ${goal.goal}`}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
