import { Cancel, Check, Delete } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  Box,
  Tooltip,
  TextField,
  Modal,
  ListItemIcon,
  MenuItem,
  Select,
  SvgIcon,
} from "@mui/material";
import { useState } from "react";
import Goals from "../../models/Goals";
import { Goal, goalTypes } from "../../types/types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const GoalRowHeaderEdit = ({
  goal,
  callback,
}: {
  goal: Goal;
  callback: () => void;
}) => {
  const [goalName, setGoalName] = useState(goal.goal);
  const [goalDuration, setGoalDuration] = useState(goal.duration);
  const [goalType, setGoalType] = useState(goal.type); // new state for goal kind


  const editGoal = () => {
    Goals.update(goal, {
      goal: goalName,
      duration: goalDuration,
      type: goalType
    });

    callback();
  };
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);

  const deleteButton = (
    <Tooltip title={`Delete Goal`}>
      <IconButton
        onClick={() => {
          if (goal?.id) Goals.delete(goal?.id);
        }}
      >
        <Delete />
      </IconButton>
    </Tooltip>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ marginTop: "32px" }}
          spacing={1}
        >
          <TextField
            value={goalName}
            onChange={(e) => setGoalName(e.target.value)}
            label="Goal Name"
          ></TextField>
          <TextField
            value={goalDuration}
            onChange={(e) => setGoalDuration(parseInt(e.target.value))}
            type="number"
            label="Goal Duration (min)"
          ></TextField>
          <Select
        value={goalType}
        onChange={(e) => setGoalType(e.target.value)}
        label="Goal Type"
      >
        {Object.entries(goalTypes).map(([value, { color, label }]) => (
          <MenuItem value={value} key={value}>
            <ListItemIcon>
              <SvgIcon>
                <circle cx="12" cy="12" r="8" fill={color} />
              </SvgIcon>
            </ListItemIcon>
            {label}
          </MenuItem>
        ))}
      </Select>


          <Tooltip title={`Submit Edit`}>
            <IconButton
              onClick={() => {
                editGoal();
              }}
            >
              <Check />
            </IconButton>
          </Tooltip>

          <Tooltip title={`Cancel Edit`}>
            <IconButton
              onClick={() => {
                callback();
              }}
            >
              <Cancel />
            </IconButton>
          </Tooltip>

          {deleteButton}
        </Stack>
      </Box>
    </Modal>
  );
};
